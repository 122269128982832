export function useBooking() {
  const runtimeConfig = useRuntimeConfig();
  const { locale } = useI18n();

  const linkBooking = (
    slug: string,
    options: Object | string = "",
    campaign: string = "default"
  ) => {
    var query = [];
    var ex = "en-gb.html";
    if (locale.value == "vi") {
      ex = "vi.html";
    }
    var q = "";
    if (typeof options === "object") {
      const keys = Object.keys(options);
      const values = Object.values(options);
      for (let i = 0; i < keys.length; i++) {
        query.push(keys[i] + "=" + values[i]);
      }
    } else {
      q = options;
    }
    const link =
      "https://www.booking.com" +
      slug +
      "." +
      ex +
      "?" +
      query.join("&") +
      "&" +
      q;
    return (
      "https://www.awin1.com/cread.php?awinmid=18117&awinaffid=1553792&ued=" +
      encodeURIComponent(link)
    );
  };
  const linkRoom = (
    slug: string,
    roomId: number | string,
    campaign: string = ""
  ) => {
    var query:any = [];
    var ex = "en-gb.html";
    if (locale.value == "vi") {
      ex = "vi.html";
    }
    const link =
      "https://www.booking.com" +
      slug +
      "." +
      ex +
      "?" +
      query.join("&") +
      "#room_" +
      roomId;

    return (
      "https://www.awin1.com/cread.php?awinmid=18117&awinaffid=1553792&ued=" +
      encodeURIComponent(link)
    );
  };

  const redirect = (url: string, target: string = "_blank") => {
    return new Promise((resolve, reject) => {
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", target);
      a.click();
      a.remove();
      resolve(url);
    });
  };

  return {
    linkBooking,
    redirect,
    linkRoom,
  };
}
